<template>
<div class="id">
  <div class="box">
  </div>
  <div class="right_image">
    <div class="right_list">
      <div class="right_list_info" @click="goTo(item)" v-for="(item,index) in rightList" :key="index">
        {{item.title}}
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "index",
  data(){
    return{
      rightList:[
        {index:0,title:'狱政管理科'},
        {index:1,title:'刑罚执行科'},
        {index:2,title:'教育改造与心理矫正科'},
        {index:3,title:'生活卫生科'},
        {index:4,title:'新入监告科'},
        {index:5,title:'活动视频'},
      ]
    }
  },
  methods:{
    goTo(item){
      this.$router.push({name:'articleInfo',params: { id: item.index }})
    }
  }
}
</script>

<style scoped lang="scss">
.id{
  background: black;
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.box{
  background-image: url('../assets/img/bj.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 70%;
  height: 70vh;
}
.right_image{
  position: fixed;
  z-index: 9;
  right: 0;
  top: 0;
  background-image: url('../assets/img/ltdb.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 35%;
  min-width: 320px;
  height: 100vh;
  display: flex;
  align-items: center;
  .right_list{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 86%;
    .right_list_info{
      background: #1B69B3;
      font-size: 24px;
      color: white;
      min-width: 240px;
      width: 65%;
      padding: 20px;
      text-align: center;
      border-radius: 16px;
      cursor: pointer;
    }
    .right_list_info:hover{
      color: red;
    }
  }
}
</style>
